/* eslint-disable max-len */
export default {
  translation: {
    common: {
      downloadApp: 'Download app',
      loading: 'Loading...',
      audioOutput: 'Speakers',
      audioOutputTest: 'Sound check',
      pipOpen: 'Open "Picture in picture"',
      pipClose: 'Close "Picture in picture"',
      pip: 'Picture in picture',
      retry: 'Retry',
      name: 'Name',
      create: 'Create',
      close: 'Close',
      title: 'Title',
      email: 'E-mail',
      room: 'Room',
      slot: 'Slot',
      save: 'Save',
      join: 'Join',
      startConference: 'Start conference',
      startWebinar: 'Start webinar',
      logout: 'Logout',
      value: 'Value',
      selectInputPlaceHolder: 'Select...',
      participant: 'Participant',
      microphone: 'Microphone',
      screenShare: 'Screen share',
      fullScreenEnter: 'Full screen',
      fullScreenExit: 'Exit full screen',
      slotFullScreenEnter: 'Slot full screen',
      slotFullScreenExit: 'Exit slot full screen',
      camera: 'Camera',
      language: 'Language',
      yes: 'Yes',
      no: 'No',
      delete: 'Delete',
      authenticating: 'Authenticating',
      to: 'To',
      myCamera: 'My camera',
      comment: 'Comment',
      empty: 'Empty',
      add: 'Add {{type}}',
      type: 'Type',
      edit: 'Edit',
      cancel: 'Cancel',
      cancel2: 'Cancel',
      ok: 'Ok',
      search: 'Search',
      description: 'Description',
      settings: 'Settings',
      image: 'Image',
      photo: 'Photo',
      yourPhoto: 'Your photo',
      general: 'General',
      account: 'Account',
      users: 'Users',
      usersOnline: 'Users online',
      view: 'View',
      continue: 'Continue',
      continueInApp: 'Continue in app',
      continueInBrowser: 'Continue in browser',
      continueWithoutDevices: 'Continue without devices',
      enterName: 'Enter Name',
      enterText: 'Enter Text',
      offline: 'Offline',
      space: 'Space',
      scenarios: 'Scenarios',
      auto: 'Auto',
      chat: 'Chat',
      owner: 'Owner',
      moderator: 'Administrator',
      user: 'User',
      guest: 'Guest',
      private: 'Private',
      public: 'Public',
      notFound: 'Not found',
      profile: 'Profile',
      devices: 'Devices',
      selectPhoto: 'Select Photo',
      select: 'Select',
      selectReplace: 'Seleccionar y reemplazar',
      userPhotoChange: 'Change',
      userPhotoAdd: 'Add',
      userPhoto: 'photo',
      share: 'Share',
      help: 'Help',
      support: 'Support',
      helpCenter: 'Help center',
      options: 'Options',
      turnOn: 'Turn On',
      turnOff: 'Turn Off',
      leaveRoom: 'Leave meeting',
      leaveIntoRoomsList: 'Leave into rooms list',
      leaveAndFinishForAll: 'Finish for all',
      redirectHint: 'You will be redirected to home page in {{ counter }} seconds',
      notAuthorized: 'You are not authorized',
      spaceBtn: 'Space',
      download: 'Download',
      download2: 'Download',
      statistics: 'Statistics',
      startDate: 'Start Date',
      endDate: 'End Date',
      reset: 'Reset',
      information: 'Information',
      bugReport: 'Bug Report',
      participants: 'Participants',
      moderators: 'Administrators',
      speakers: 'Speakers',
      noSlotsParticipants: 'No slots participants',
      until: 'Until {{endOfSubscription}}',
      change: 'Change',
      password: 'Password',
      displayName: 'Display Name',
      submit: 'Submit',
      registration: 'Registration',
      knowledgeBase: 'Help center',
      faq: 'FAQ',
      integration: 'Integration',
      refresh: 'Refresh',
      reload: 'Reload',
      refreshThePage: 'Refresh the page',
      openHomePage: 'Open home page',
      get: 'Get',
      installIOSAlert: {
        title: 'Try the service',
        subtitle1: 'Give classes and webinars from your mobile phone:',
        subtitle2: 'screen share',
        subtitle3: 'fullscreen mode',
        subtitle4: 'and chat moderation.',
        installButton: 'Install',
        skipButton: 'Skip',
      },
      page: 'Page',
      allow: 'Allow',
      forbid: 'Forbid',
      raiseHand: 'Raise hand',
      putHandDown: 'Put hand down',
      raisedHands: 'Raised hands',
      backToSpaces: 'Back to my spaces',
      announcements: 'Announcements',
      breakoutRooms: 'Breakout Rooms',
      reactions: 'Reactions',
      clear: 'Clear',
      requiredToFill: 'Required',
      required: 'Required',
      warnings: {
        datePickerWarning: 'Date can be from {{ startDate }} to {{ endDate }}',
        callTimeRestrictionWarning: {
          message1: 'Call time is limited to {{ kickOutTimeMin }} minutes for {{ freePlanName }} plan. ',
          message2: '',
          message3: ' minutes left until the end of the call',
          messageElectron: '{{ time }} left until the end of the call',
        },
        callTimeRestrictionPlansButton: 'Pick plan and prolong call',
      },
      inputPeriodLabel: 'Choose the date or date range',
      current: 'Current',
      monthly: 'Моnthly',
      annual: 'Annual',
      month: 'month',
      brand: {
        livedigital: 'livedigital',
        moodhood: 'courseboost',
        teleboss: 'TeleBoss',
      },
      company: {
        livedigital: 'LiveDigital LLC',
        moodhood: 'Courseboost LLC',
        teleboss: 'Teleboss LLC',
      },
      pageTitle: {
        livedigital: 'livedigital',
        moodhood: 'courseboost',
        teleboss: 'TeleBoss',
        description: 'Online meetings and webinars',
      },
      sort: 'Sort',
      spaceUsers: 'Space users',
      spaceSettings: 'Space settings',
      schedule: 'Schedule',
      preview: 'Preview',
      video: 'Video',
      weekDaysShort: 'Mon Tue Wed Th Fri Sat Sun',
      timeZone: 'Time zone',
      repeating: 'Repeating',
      notAdded: 'Not added',
      videoUpload: 'Video upload',
      themeAndLanguage: 'Color theme and language',
      link: 'Link',
      key: 'Key',
      copied: 'Copied',
      copyLink: 'Copy link',
      on: 'On',
      off: 'Off',
      sound: 'Sound',
      rooms: 'Rooms',
    },
    join: {
      signIn: 'Sign in with an account',
      joinedAs: 'You are joining as',
      changeAccount: 'Sign in with a different account',
      step1: {
        field: 'User name',
        label: 'Enter your name',
        agreements1: 'By clicking "{{continue}}" you acknowledge having read and agreed to our ',
        agreements2: 'Terms of Use',
        agreements3: ' and ',
        agreements4: 'Privacy Policy',
        clientAgreement1: ', also with ',
      },
      step4: {
        joinTitle: 'You are about to join into room {{name}}',
        hint: 'Nobody can hear or see you until you join the event',
        title: 'Select your devices',
        backToRooms: 'Back to events',
      },
      deviceWizard: {
        begin: 'Start',
        viewManual: 'View the instruction',
        accessGranted: 'Access is allowed',
        requestAccess: 'Request access',
        TopBlockSafariError: 'Your browser has blocked access to your devices',
        BottomBlockHint: {
          title: 'Allow access to your devices',
          hint: 'For others to see and hear you, your browser will request access to your camera and microphone.',
        },
        BottomBlockChromeError: {
          title: 'Your browser has blocked access to your devices',
          hint: [
            'Click on the camera icon in the far right corner of the URL bar.',
            'Select "Always allow" and then "Done".',
          ],
        },
        BottomBlockSafariError: [
          'You need to allow access to your camera and microphone to join.',
          'Try again and select',
          ' "Allow" ',
          'when prompted.',
        ],
      },
    },
    auth: {
      welcome: 'Welcome!',
      forgotPassword: 'Forgot Password?',
      signIn: 'Sign In',
      capsLockIsOn: 'CapsLock is On',
      and: ' and ',
      passwordIsRequired: 'The password is required',
      passwordMinLength: 'The password must not be shorter than {{value}} characters.',
      enterYourPassword: 'Enter your password',
      createYourPassword: 'Create a password of 6 characters',
      password: 'Password',
      emailIsRequired: 'The email is required',
      phoneIsRequired: 'The phone number is required',
      emailMustBeAValid: 'The email must be a valid',
      phoneMustBeAValid: 'The phone number must be a valid',
      enterYourEmail: 'Enter your email',
      enterYourPhone: 'Enter your phone number',
      email: 'E-mail',
      phone: 'Phone number',
      phoneMinLength: 'The phone number must not be shorter than {{value}} characters.',
      phoneMaxLength: 'The phone number cannot be longer than {{value}} characters',
      signUp: 'Sign Up',
      donTHaveAnAccount: 'Don`t have an account? ',
      wrongUsername: 'Wrong username',
      orPassword: 'or password',
      agreements1: 'By clicking "{{registration}}" you acknowledge you have read and agreed to our ',
      agreements2: 'Terms of Use',
      agreements3: ' and ',
      agreements4: 'Privacy Policy',
      usernameMaxLength: 'The Username cannot be longer than {{value}} characters',
      usernameMinLength: 'The Username must not be shorter than {{value}} characters.',
      usernameIsRequired: 'The username is required',
      usernameNEEmail: 'The username cannot be equal to the email.',
      enterYourUsername: 'Enter your username',
      username: 'Username',
      alreadyHaveAnAccount: 'Already have an account? ',
      emailIsAlreadyTaken: 'An account with such an e-mail already exists. Try entering a different e-mail or log in to an existing account.',
      passwordRecovery: 'Password recovery',
      hintForPasswordRecovery: 'an email will be sent to you with a link to recover your password',
      getLink: 'Get link',
      enterEmail: 'Enter email',
      checkYourEmail: 'Check your email {{email}}',
      agree: 'I agree to receive {{brand}}-related newsletters from {{company}}.',
      agreeIsRequired: 'Agreement is required',
      purposeUsing: 'The purpose of using the {{brand}}',
      purposeUsingPlaceholder: 'Select the purpose of use',
      personal: 'Personal use',
      company: 'Solution for the company',
    },
    moderator: {
      afterSignupMobileAlert: 'Full functionality is available in the desktop version',
      moveParticipant: 'Move',
      reJoin: 'Rejoin',
      kick: 'Kick',
      chooseRoom: 'Choose room',
      chooseSlot: 'Choose slot',
      notifications: {
        roomLoadingError: 'Room loading error',
        moveFail: 'Failed to move participant',
        moveFailOfPermissionsErr: 'Could not move participant. Please make sure that the participant has proper access rights to enter the room',
        moveScreenSharingFail: 'Screen sharing cannot be moved',
        reJoinFail: 'Failed to rejoin participant',
        kickFail: 'Failed to kick participant',
      },
      disableCamera: 'Disable participant\'s camera',
      disableMicrophone: 'Disable participant\'s microphone',
      enableCamera: 'Enable participant\'s camera',
      enableMicrophone: 'Enable participant\'s microphone',
      turnOffCamera: 'Turn off participant\'s camera',
      turnOffMicrophone: 'Turn off participant\'s microphone',
      usersDlgDescription: 'Manage user access by assigning roles',
      usersInSpace: 'Users in {{ spaceName }}',
      dumpPeerInfo: 'Dump peer debug info',
      dumpTrackInfo: 'Dump track debug info',
      dumpRoomInfo: 'Dump room debug info',
      setOutOfScreen: 'Remove from screen',
      setOnScreen: 'Move to screen',
      peerOnScreenInWebinarReachedLimit: 'Only 3 speakers can be shown on the screen at the same time',
      participantRequest: 'Request to join the room',
      participantAwaits: '{{name}} wants to join room',
      disableScreenDemonstration: 'Disable screen demonstration',
    },
    errors: {
      spaceWithIdNotFound: 'Space with id "{{spaceId}}" not found',
      miroPickerError: 'Failed to load board',
      pipStartError: 'Can\'t open "Picture in picture": {{ error }}',
      roomRecordError: 'Error room recording',
      somethingWentWrong: 'Something went wrong',
      somethingWentWrongBody: 'Try reloading the page or try again later.',
      participantNotFound: 'Participant not found.',
      invalidSpace: 'You are registered in another space.',
      required: 'Required',
      invalidEmail: 'Invalid email',
      participantNotRegistered: 'Participant not registered',
      participantAlreadyOnline: 'Participant is already online on another device',
      noEntryRoomSpecified: 'No entry room specified',
      roomNotFound: 'Room not found',
      accessDenied: 'Access Denied',
      noFreePlacesInRoom: 'No slots available in the room',
      scenarioNotFound: 'Scenario is not found',
      shortcutNotFound: 'Shortcut is not found',
      actionNotFound: 'Action not found',
      yourDeviceIsDisconnected: 'Your device is disconnected from the space',
      deleteRecordError: 'Failed to delete record',
      reportDownloadError: 'Failed to download report',
      dndDestSlotIsBoard: 'Destination slot is used by board, close it to free the slot',
      alreadyTranslatingSlotToRoom: 'The slot is already being broadcasted to the selected room',
      alreadyTranslatingToSlot: 'Another broadcast is already started to the selected slot',
      reportNoDataError: 'No data found for the specified period of time',
      userRoleChangeFailed: 'Failed to change user role',
      noAvailableDevice: 'No available device',
      devicesEnumerateError: 'Failed to get device list',
      devicesPermissionError: 'Devices permission denied',
      videoDevicesPermissionError: 'Could not access camera. Please make sure that access to the device is granted, that the device is not used by another application and is not blocked at the operating system level',
      audioDevicesPermissionError: 'Could not access microphone. Please make sure that access to the device is granted, that the device is not used by another application and is not blocked at the operating system level',
      screenPermissionError: 'Could not access the screen. Please make sure that access to the screen is granted and is not blocked at the operating system level',
      devicesTrackError: 'Failed to create media track',
      devicesGeneralError: 'Media device error',
      tooShortName: 'Name is too short',
      joinProblems: 'Problems with joining',
      deviceNotSupportedError: 'The browser or device is not supported. Try changing your browser or device. If the error still appears, please contact support',
      chatUserUpdateInfo: 'Failed to update user information in chat',
      cookiesBlockedError: 'Your browser is blocking cookies. For the application to function correctly, you must allow the use of cookies in your browser settings',
      authenticationError: {
        text: 'Authentication error',
        hint: 'Your browser is blocking cookies. For the application to function correctly, you must allow the use of cookies in your browser settings',
      },
      thisRoomDoesNotExist: {
        errorText: 'This room does not exist',
        hint: 'Check if the entered URL is valid',
      },
      invalidRoomInvite: {
        errorText: 'The invite to the room is invalid',
        hint: 'Contact the room administrator',
      },
      invalidSpaceInvite: {
        errorText: 'The invite to the space is invalid',
        hint: 'Contact the space administrator',
      },
      TechnicalWork: {
        errorText: 'Service is temporarily unavailable',
        hint: 'Please try to check later',
      },
      inviteExpiredOrNotExists: {
        errorText: 'The invite to the space is invalid',
        hint: 'Contact the space administrator',
      },
      noPermissionForSpace: {
        errorText: "You don't have permission to enter this space",
        hint: 'Contact the space administrator',
      },
      confirmationTokenInvalid: {
        errorText: 'Confirmation token is invalid or has already been used',
        hint: 'Try to request another link with a proper token',
      },
      noPermissionForRoom: {
        errorText: 'You don\'t have permission to enter this room',
        hint: 'Contact the room administrator',
      },
      inviteConflict: {
        errorText: 'User already invited to the space',
      },
      problemWithDevices: 'Problem with devices',
      mobileJoinToastHint: 'Go to help for more information',
      blockedByAntivirus: 'Connection is blocked by antivirus',
      blockedByFirewall: 'Connection is blocked by firewall',
      screensharingTogglePolicyError: 'Failed to enable screen share due to application permission policy',
      screensharingTogglePermissionError: 'Failed to enable screen share. Please make sure you have granted permissions to share the screen',
      screensharingToggleUnexpectedError: 'Failed to enable screen share',
      turnOffDevicesError: 'Switching off devices is failed',
      switchOffDevicesError: 'Switching off devices is failed',
      enableDevicesError: 'Enabling device is failed',
      disableDevicesError: 'Disabling device is failed',
      failedToFetchTimeZones: 'Failed to load time zones',
      chatReload: {
        line1: 'Oops, chat didn\'t load.',
        line2: 'Click "{{ reload }}" to try again.',
      },
    },
    room: {
      backToSpace: 'Back to the space',
      talkWhileMuted: 'You are muted',
      invite: 'Invite',
      inviteParticipant: 'Invite participant',
      spaceUsers: 'Space users',
      copied: 'Link copied to clipboard',
      selectSharingSlot: 'Select a slot to share your screen',
      confirmExit: {
        title: 'Confirmation of exit',
        text: 'Are you sure you want to leave the room?',
        confirmButton: 'Leave',
        dismissButton: 'Cancel',
      },
      confirmKickAllAndExit: {
        title: 'Exit confirmation',
        text: 'Are you sure you want to leave the room and end meeting for all?',
        confirmButton: 'End for all and leave',
        dismissButton: 'Cancel',
      },
      participantNameStub: 'Participant name is not specified',
      registrationHint: {
        title: 'Start using {{brand}}',
        text: 'Register and host your own conferences',
      },
      inviteHint: {
        title: 'Invite participants',
        text: 'Click on the button and send the link to those you want to invite to this conference',
        buttonText: 'Copy link',
      },
      microphone: {
        turnOn: 'Turn on',
        turnOff: 'Turn off',
        turnOnMicrophone: 'Turn on your microphone',
        turnOffMicrophone: 'Turn off your microphone',
        microphoneIsOff: 'Microphone is off',
        microphoneIsOn: 'Microphone is on',
        turnOffForAll: 'Turn off microphone for all',
        changeInput: 'Change microphone',
        changeOutput: 'Change speaker',
        settings: 'Microphone settings',
        enableNoiseSuppression: 'Enable software noise suppression',
        noiseSuppressionError: 'Noise suppression module error',
        microphoneOffWarning: 'Nobody hears you, your microphone is off',
        microphoneNotReactingWarning: 'Your microphone "{{ microphoneName }}" is on, but is not reacting, Please try to change it with another available one or set in yourself',
        devicesSettings: 'Devices settings',
        changeMicrophone: 'Change microphone',
        microphoneIsChanged: 'Microphone has been changed with {{ microphoneName }}',
      },
      camera: {
        turnOn: 'Turn on',
        turnOff: 'Turn off',
        turnOnCamera: 'Turn on your camera',
        turnOffCamera: 'Turn off your camera',
        cameraIsOff: 'Camera is off',
        cameraIsOn: 'Camera is on',
        turnOffForAll: 'Turn off cameras for all',
        changeInput: 'Change camera',
        settings: 'Camera settings',
        enableBackgroundBlur: 'Enable background blur',
        backgroundBlurError: 'Virtual background module error',
      },
      demo: {
        demoButtonHintTitle1: '{{ names }} {{ wordsEndingDemos }}',
        demoButtonHintTitle1AndMore: ' and {{ count }} more',
        demoButtonHintContent1: 'You can only show {{ limit }} demo screens',

        screenSharing: 'Screen Sharing',
        screenSharingStart: 'Start share',
        screenSharingStop: 'Stop share',
        screenSharingChange: 'Change Screen',

        board: 'Miro Board',
        boardInUse: 'Miro Board is already in use',
        boardLimit: 'You can only use one Miro board display',
        boardLimitNoBoard: 'You can only use only 2 demo slots',
        boardStart: 'Start Miro',
        boardStop: 'Stop Miro',
        boardChange: 'Change Board',
        boardStartError: 'Failed to start Miro board',

        available: 'Available',
        notAvailable: 'Not available',
        taken: 'Taken',
        usedByMe: 'Used by you',
        demoUsed: '{{ userName }} is screen sharing',
        broadcast: 'Broadcast',
        broadcastInfo: 'Broadcast information (link and key)',
        broadcastStart: 'Start broadcast',
        broadcastStop: 'Stop broadcast',
        broadcastLimit: 'You can use only one broadcast',
        broadcastStartError: 'Failed to start broadcast',
        broadcastStopError: 'Failed to stop broadcast',
        broadcastDlgTitle: 'Start broadcast',
        broadcastDlgText: 'Copy link and key to use them in OBS stream settings and start broadcast.',
        broadcastDlgText2: 'When it starts, you will see it in a slot.',
        broadcastInfoTitle: 'Broadcast info',
        broadcastNoOwner: 'Broadcast\'s user is absent',
        broadcastCopyKey: 'Copy key',
        electron: {
          screens: 'Screens',
          windows: 'Windows',
          withSound: 'Enable demo screen sound',
          start: 'Screen sharing',
          cancel: 'Cancel',
        },
      },
      feedback: {
        satisfied: 'Are you satisfied with the quality of our service?',
        explain: 'Could you please explain your difficulties?',
        send: 'Send',
        problem1: 'Had problems with joining a room',
        problem2: 'Could not hear or see other participants',
        problem3: 'Non-intuitive user interface',
        thanks: 'Thanks for your help!',
      },
      stillHere: {
        title: 'Are you still here?',
        text1: 'The meeting will end in',
        text2: 'To continue, press «Continue» button',
      },
      onAir: {
        guest: {
          wait: 'Broadcast will start soon',
          waitHint: 'Waiting for the broadcast',
        },
        moderator: {
          banner: {
            notReady: "Broadcast hasn't started yet",
            notReadyHint: 'You can start the broadcast as a speaker or stay as an administrator without entering the broadcast. You can become a speaker or stop being a speaker in the user list tab.',
            waiting: 'Waiting for the broadcast',
            waitingHint: 'You are the broadcast moderator. You can become a speaker in the user list tab.',
            speakerButton: 'Start the broadcast and become a speaker',
            moderatorButton: 'Stay as an administrator',
          },
          toast: {
            title: 'Would you like to join as a speaker?',
            hint: 'You can join as a speaker or stay as an administrator without entering the broadcast. You can become a speaker or stop being a speaker in the user list tab.',
            speakerButton: 'Become a speaker',
            moderatorButton: 'Continue as an administrator',
          },
        },
      },
      putHandDown: {
        toastMessage: 'Looks like you\'ve already spoken, your hand will be lowered',
        keepRaised: 'Keep raised',
      },
      conferenceHint: 'Online meeting with video and audio. Perfect for real-time discussions and meetings.',
      webinarHint: 'An online event for a large audience. Participants follow the presentation and interact via chat.',
    },
    event: {
      pleaseWait: 'Please wait, the webinar will start soon',
      webinarIsOver: 'The webinar is over',
      webinarNotReady: 'The webinar has not started or is already over',
      thankYouForParticipating: 'Thank you for participating',
    },
    warnings: {
      forbiddenBroadcastAudio: 'Administrator has disabled your microphone',
      forbiddenBroadcastVideo: 'Administrator has disabled your camera',
      allowedBroadcastAudio: 'Administrator has enabled your microphone',
      allowedBroadcastVideo: 'Administrator has enabled your camera',
      unstableConnection: 'Unstable connection',
      unstableConnectionCommon: 'Unstable internet connection',
      unstableConnectionUserCameraOff: 'Unstable internet connection. your camera turned off',
      unstableConnectionCamerasOff: 'Unstable internet connection. cameras turned off',
      unsupportedOS: {
        msg: 'The service might work unstable with {{os}} version below {{minversion}}',
        hint: 'Please use desktop version for more comfortable work',
        buttonText: 'Enter',
      },
      takeOffVideoForScreenSharing: 'Weak internet connection, turn off video to improve screen sharing',
      takeOffVideoForAudio: 'Weak internet connection, turn off camera to improve your audio',
      takeOffVideos: 'Weak internet connection, participants video has been disabled',
      adminSwitchedOffVideo: 'Administrator has turned off your camera',
      adminSwitchedOffAudio: 'Administrator has turned off your microphone',
      adminSwitchedOffScreenSharing: 'Administrator has turned off your screen sharing',

      adminTurnedOffCamera: 'Administrator has turned off your camera',
      adminTurnedOffMicrophone: 'Administrator has turned off your microphone',
      adminTurnedOffScreenSharing: 'Administrator has turned off your screen sharing',
    },
    invites: {
      activate: 'Activate',
      activated: 'Invite activated',
      activateInvite: 'Activate invite?',
      return: 'Return',
    },
    integration: {
      personalToken: {
        title: 'Personal tokens',
        saveTokenDialogTitle: 'Confirm saving the token',
        saveTokenTip: 'Save the token in a safe place. After closing this window, it will not be possible to see the token again',
        whatIsIt: 'The personal token is used for authorization in the API on your behalf. The token does not have an expiration date, unlike a regular OAuth2 access token. It is recommended to refresh tokens regularly for security purposes. Do not pass it on to third parties',
        noTokensText: 'You do not have personal tokens',
        tokenSavedApprovalBtn: 'Confirm saving the token',
        failedToFetchTokensError: 'Failed to fetch personal tokens',
      },
    },
    chat: {
      activate: 'Activate Chat',
      activateFail: 'Failed to activate chat',
      displayChatFail: 'Failed to display chat',
      selectFileMenu: {
        title: 'Add attachments',
        photo: 'Make photo',
        media: 'Add media',
        file: 'Add file',
        fileTooBig: 'Selected file is too big',
      },
    },
    admin: {
      menu: {
        admin: 'Administrator',
        users: 'Users',
        debug: 'Debug',
      },
      notifications: {
        connectionLost: 'Connection lost. Waiting for connection...',
        connectionEstablished: 'Connection established.',
        connectionEstablishedRejoining: 'Connection established. Rejoining the room...',
        roomRejoined: 'Successfully reconnected',
        linkCopied: 'Link copied to clipboard',
      },
      confirmation: {
        userMove: 'Do you really want to move user {{name}} to slot {{slotId}}?',
        kick: 'Remove this participant?',
      },
      spaces: {
        title: 'Spaces',
        createButtonLabel: 'New space',
        deleteButtonLabel: 'Delete space',
        deleteConfirm: 'Delete space',
        deleteTitle: 'Delete conference',
        deleteMessage: 'Are you sure you want to delete it?',
        description: 'Description',
        logo: 'Logo',
        saveSuccess: 'Space successfully saved',
        saveFail: 'Space saving error',
        deleteFail: 'Space deletion fail',
        mySpaces: 'My Spaces',
        createNewSpace: 'Create New Space',
        noImage: 'No Image',
        private: 'Private',
        public: 'Public',
        type: 'Space type',
        noSpaces: 'Spaces have not been created',
        logoCropper: {
          title: 'Logo selection',
          cropperTitle: 'Select an area',
          previewTitle: 'Preview',
        },
        buyPlanHint: 'Please select a space to buy plan for, after that a dialog with buying options will open',
        titleHintTooltip: 'Combines room links for convenience. Organize meetings by topic or project.',
        defaultSpaceName: 'My Space',
      },
      rooms: {
        lessonDefaultName: 'Your first room',
        webinarDefaultName: 'Your first webinar',
        saveFail: 'Room saving error',
        saveSuccess: 'Meeting successfully saved',
        saveJoinSettingsFail: 'Failed to save room join fields',
        delete: {
          confirmTitle: 'Delete room "{{roomName}}"?',
          confirmText: 'Are you sure you want to delete the room?',
        },
        loadFail: 'Failed to load rooms',
      },
      playbacks: {
        schedule: 'Schedule',
        withRepeating: '{{value}} with repeating',
        withoutRepeating: '{{value}} without repeating',
        constant: '{{value}} constant',
        notPublished: 'Not published',
        nowPlaying: 'Now playing',
        totalStarted: 'Total started',
        totalMembers: 'Total members',
        editPlayback: 'Edit',
        playbackEventsList: 'To playback events list',
        playbacks: 'Autowebinars',
        playbacksDescription: 'Create, edit and view recorded events',
        playbackCreate: 'Create autowebinar',
        published: 'Published',
        unpublished: 'Drafts',
        favorites: 'Favorites',
        createError: 'Failed to create playback',
        updateError: 'Failed to update playback',
        downloadTimelineFailed: 'Failed to download interactions json',
        uploadTimelineFailed: 'Failed to upload interactions json',
        deleteTimelineFailed: 'Failed to delete interactions json',
        timeLineWrongFormat: 'Invalid interactions json file format',
        timeLineDeleteTitle: 'Delete interactions?',
        timeLineDeleteWarn: 'Are you sure you want to delete interactions?',
        videoeDeleteTitle: 'Delete video?',
        videoDeleteWarn: 'Are you sure you want to delete video?',
        signVideoFailed: 'Failed to sign video',
        deleteVideoFailed: 'Failed to delete video',
        updatePlaybackFailed: 'Failed to update autowebinar',
        createAssetFailed: 'Failed to create video asset',
        timeLineFileSizeError: 'Json file size is too big, should be less than {{size}}',
        videoFileSizeError: 'Video file size is too big, should be less than {{size}}',
        fetchPlaybacksError: 'Failed to fetch playbacks',
        fetchPlaybackError: 'Failed to fetch playback',
        removePlaybackError: 'Failed to remove playback',
        uploadVideoFailed: 'Failed to upload video',
        remove: {
          confirmTitle: 'Remove autowebinar "{{ name }}"?',
          confirmText: 'Are you sure you want to delete the autowebinar?',
        },
        publish: {
          confirmTitle: 'Publish {{ name }}',
          confirmText: 'Are you sure you want to publish an autowebinar?',
        },
        unpublish: {
          confirmTitle: 'Unpublish "{{ name }}"?',
          confirmText: 'Are you sure you want to unpublish the autowebinar?',
        },
      },
      playbackEvents: {
        remove: {
          confirmTitle: 'Remove schedule?',
          confirmText: 'Are you sure you want to delete the schedule?',
          fail: 'Failed to remove schedule',
        },
      },
      playbackEdit: {
        schedule: 'Schedule',
        content: 'Content',
        commonSettings: 'Common settings',
        inputNameLabel: 'Name',
        inputNamePlaceholder: 'Enter the name',
        enteringFields: 'Join fields',
        phone: 'Phone',
        exit: 'Exit',
        unPublish: 'Unpublish',
        remove: 'Remove',
        publish: 'Publish',
        addContent: 'To publish, add content.',
        autoSave: 'Autosave {{ autoSaveTime }}',
        saveFail: 'Playback saving error',
        noScheduleMessage1: 'Autowebinar hasn\'t planned yet',
        noScheduleMessage2: 'Please add autowebinar schedule',
        addSchedule: 'Add schedule',
        scheduleCreating: 'Schedule creating',
        scheduleEditing: 'Schedule editing',
        oneTime: 'One-time',
        recurrent: 'Recurrent',
        persistent: 'Persistent',
        startDayOneTimeLabel: 'Start date and time',
        startDateRecurrentLabel: 'Start date and time',
        endDateLabel: 'End date',
        hasNoFinish: 'No end date',
        createError: 'Failed to create schedule',
        updateError: 'Failed to update schedule',
        successCreate: 'Schedule is created',
        successUpdate: 'Schedule is updated',
        daysOfWeek: 'Days of week',
        everyDay: 'Every day',
      },
      classrooms: {
        shareRoom: 'Invite a participant',
        lessonAnalytic: 'Statistic on participants',
        editing: 'Edit room',
        name: 'Name',
        lessonType: 'Room type',
        createClassroom: 'Create new room',
        enterName: 'Enter the name',
        typeLesson: 'Conference',
        typeWebinar: 'Webinar',
        typeAutoWebinar: 'Autowebinar',
        createVirtualClassroom: 'Create',
        joinClassroom: 'Enter',
        all: 'All',
        deleteClassroom: 'Delete the room',
        saveClassroom: 'Save room',
        joinHint: {
          title: 'Hold the first meeting',
          text: 'Enter the room and invite the meeting participants.',
        },
        joinFields: 'Join fields',
        email: 'E-mail',
        phone: 'Phone',
        newLesson: 'Conference',
        newWebinar: 'Webinar',
        newPlaybackWebinar: 'Autowebinar',
        usersOnline: 'Users online: {{onlineUsersCount}}',
        usersOnlineShort: 'Users online: {{onlineUsersCount}}',
        roomSettings: 'Room settings',
        roomRecords: 'Room records',
        allRooms: 'All rooms',
        onlineMeetings: 'Online meetings',
        listDescription: 'Rooms for online conferences and webinars',
        conferences: 'Conferences',
        webinars: 'Webinars',
        favorites: 'Favorites',
        sort: 'Sort by',
        lastCreated: 'Last created',
        lastCall: 'Last active call',
        autoWebinars: 'Autowebinars',
        comingSoon: 'Your autowebinars will be here soon',
        createMeetingDialogTitle: 'Creating a new meeting',
        editMeetingDialogTitle: 'Edit meeting',
        playbackWebinarName: 'The name of the autowebinar',
        enterPlaybackWebinarName: 'Enter the name of the autowebinar',
        autoRecordLabel: 'Automatic recording on administrator enter',
        autoRecordHelpTooltip: 'Recording starts automatically when administrator enters the room',
        inviteSpeaker: 'Invite speaker',
        speakerLinkCopied: 'Speaker invitation link copied to clipboard',
        failedToCreateSpeakerInvite: 'Failed to create speaker invitation link',
      },
      assets: {
        title: 'Assets',
        deleteConfirm: 'Delete asset',
        video: 'Video',
        audio: 'Audio',
        image: 'Image',
        uploadFail: 'Asset upload error',
        uploadSuccess: 'Asset uploaded successfully',
        uploadStart: 'Asset upload started',
        saveSuccess: 'Asset successfully saved',
        deleteFail: 'Asset deletion fail',
      },
      invites: {
        invite: 'Invite',
        personal: 'Personal invite',
        link: 'Invitation Link',
        inviteAs: 'Invite link to space as',
        copyLink: 'Copy Link',
        list: 'User list',
        inviteModerator: 'Add administrator',
        inviteMember: 'Add participant',
        showUserList: 'Show user list',
      },
      videoEffects: {
        applyHint: 'To apply press "Apply effects"',
        applyEffects: 'Apply effects',
        applyReset: 'Apply effects reset',
        resetButton: 'Reset all effects',
        visualEffects: 'Visual effects',
        blur: 'Background blur',
        blurLabel: 'Background blur intensity',
        background: 'Background',
        beautification: 'Beautification',
        beautificationLabel: 'Beautification intensity',
        colorCorrector: 'Color corrector',
        colorCorrectorLabel: 'Color corrector intensity',
        lowLightEffect: 'Low light effect',
        lowLightEffectLabel: 'Low light effect intensity',
        smartZoom: 'Smart zoom',
        smartZoomLabel: 'Smart zoom value',
        layout: 'Layout',
        colorFilter: 'Color filter',
        colorFilterLabel: 'Color filter intensity',
        pickColor: 'Pick background color',
        backgroundVideo: 'Video',
        backgroundImage: 'Image',
        backgroundColor: 'Color',
        loadingVideoEffectsEngine: 'Loading video effect engine',
        faceLeft: 'Left',
        faceCenter: 'Default',
        faceRight: 'Right',
        pickImage: 'Pick background image',
        pickVideo: 'Pick background video',
        lowerThird: 'Lower third',
        lowerThirdTitle: 'Title',
        lowerThirdSubtitle: 'Subtitle',
        lowerThirdType: 'Type',
        lowerThirdColor: 'Color',
        imageLoadError: 'Failed to load image',
        imageTooBig: 'Failed to upload image, image size must be less than 5 Mb',
        imageUnsupported: 'Image type not supported',
      },
    },
    records: {
      removeRecordHint: 'Remove record',
      renameRecordHint: 'Rename record',
      renameError: 'Record rename error',
      removeRecordTitle: 'Remove record "{{ name }}"?',
      removeRecordBody: 'Are you sure you want to delete this record?',
      renameRecord: 'Rename record',
      public: 'Public',
      download: 'Download records',
      searchIn: 'Search in {{ text }}',
      start: 'Record',
      starting: 'Record',
      stop: 'Stop',
      title: 'Videos',
      recordSelectTitle: 'Choosing a video recording for an autowebinar',
      userSpaceInSpace: 'Storage used in space: {{ user }} / {{ quota }}',
      video: 'Video',
      admin: {
        delay100s: 'Recording will start within 100 seconds',
        started: 'Recording started',
        stopped: 'Recording stopped',
      },
      user: {
        started: 'Meeting recording started',
        alreadyStarted: 'This event is recording. By joining you agree your participation will be recorded',
        recordTooltip: 'Meeting is being recorded',
      },
      publishing: {
        noDate: 'Not published',
        unPublishDate: 'Unpublished on {{ date }} at {{ time }}',
        publishDate: 'Published on {{ date }} at {{ time }}',
        viewCounter: '{{ count }} {{ word }}',
        unPublish: 'Unpublish',
        publish: 'Publish',
        copyLink: 'Copy the recording link',
      },
      onlyPublished: 'Show only published',
      downloadCompleted: '{{ filename }} download completed',
      disabledRecordingButton: 'Recording disabled',
      disabledRecordingHint: 'Recording is not available for {{ freePlanName }} plan',
      openPlansLink: 'Buy plan and get recording ability',
    },
    userProfile: {
      changeEmail: 'Change email',
      currentEmailLabel: 'Current email',
      newEmailLabel: 'New email',
      newEmailPlaceholder: 'Enter new email',
      newEmailRequired: 'New email required',
      confirmEmailLabel: 'Confirm new email',
      confirmEmailPlaceholder: 'Enter new email',
      confirmEmailRequired: 'Required to confirm new email',
      emailMismatch: 'Emails mismatch',
      emailChanged: 'Email successfully changed',
      requestChangeEmailSuccess: 'Link for email changing, have been sent to your email',
      changeEmailHint: 'To confirm the email change, follow the link sent to {{email}}. If you haven\'t found the email, check the "Spam" folder, as well as other folders and filters',
      changePassword: 'Change password',
      currentPasswordLabel: 'Current password',
      currentPasswordPlaceholder: 'Enter your current password',
      currentPasswordRequired: 'Required current password',
      newPasswordLabel: 'New password',
      newPasswordPlaceholder: 'Enter new password',
      newPasswordRequired: 'Required new password',
      confirmPasswordLabel: 'Confirm new password',
      confirmPasswordPlaceholder: 'Enter new password',
      confirmPasswordRequired: 'Required to confirm new password',
      passwordChanged: 'Password changed',
      incorrectCurrentPassword: 'Incorrect current password',
      passwordMismatch: 'Password mismatch',
      thumbnailSelection: 'Thumbnail selection',
      selectArea: 'Select an area for small photos',
      preview: 'Preview',
      profilePhotoChanged: 'Profile photo changed',
      changePasswordHint: 'An email with instructions on how to reset your password has been sent to {{email}}. Click the password reset link to set a new password.',
      confirmAction: 'Confirm action',
      profilePhotoRemoved: 'Profile photo removed',
      resetPassword: 'Reset password',
    },
    payment: {
      planTitle: 'Pricing plan',
      plansTitle: 'Pricing plans',
      hint: 'Single license per school',
      trial7Days: 'Trial',
      unlimited: 'Unlimited',
      starter: 'Starter',
      professional: 'Pro',
      business: 'Business',
      selectPlan: 'Buy',
      lifetime: 'For a Lifetime',
      month1: '1 month',
      month3: '3 month',
      month12: '12 month',
      paymentDone: 'The plan has been purchased :)',
      plan: 'Plan',
      upgradePlanTimer: 'Room not paid - {{timeToUpgrade}} to finish',
      upgradePlanTimerModerator: 'Upgrade account - {{timeToUpgrade}} to finish',
      changePlanFail: 'Failed to change current plan',
      fetchPlansFail: 'Failed to get list of plans',
      changePlanSuccess: 'Plan has been changed',
      currentPlan: 'Current plan',
      upgradePlan: 'Upgrade plan',
      closeToEndInfo1: 'Your current «{{planName}}» Plan will expire {{ daysText }}',
      closeToEndDays: 'in {{daysToEnd}} {{day}}.',
      closeToEndToday: 'today',
      choosePlan: 'Choose a plan',
      prolongPlan: 'Prolong the plan',
      planParticipants: 'Pricing plan participants',
      extraParticipants: 'Extra participants',
      recommended: 'Recommended',
      changePlan: 'Change plan',
      featureOneTitle: 'Practical lesson',
      featureTwoTitle: 'Webinar',
      pricePPM: 'Custom pricing',
      ppmHint: 'Per minutes billing',
      freeFeatures: 'Everything in the <0>{{ freePlanName }}</0> plan',
      discountUpTo: 'Discount up to {{ discount }}%',
      promoUpTo: 'Promo up to {{ discount }}%',
      promoUpToDate: 'Promo till {{ date }}',
      timeless: 'Timeless',
      freePlan: {
        priceHint: 'Forever',
        mainFeatures: [
          ['Meeting Duration', ['30 minutes']],
          ['Conference mode', ['up to 10 participants']],
          ['Webinar mode', ['up to 25 participants']],
        ],
        extraFeaturesTitle: 'Included in the plan:',
        extraFeatures: [
          'Session analytics',
          'Reactions and polls, chat',
          'Miro',
          'User management',
        ],
        buyButton: '',
      },
      personalPlan: {
        title: 'Personal',
        priceHintMonthly: 'Monthly billing',
        priceHintAnnual: 'Annual billing',
        mainFeatures: [
          ['Minutes included', ['25000']],
          ['API Access', ['No']],
          ['Recording', ['HD']],
          ['Recording Storage', ['300 minutes (5 hours)']],
          ['Recording Sharing', ['Yes']],
          ['Autowebinars (Promo)', ['Yes']],
          ['Conference', ['up to 100 participants']],
          ['Webinar', ['up to 500 participants']],
        ],
        extraFeaturesTitle: 'Included in the plan:',
        extraFeatures: [],
        buyButton: 'Buy',
      },
      teamPlan: {
        title: 'Team',
        priceHintMonthly: 'Monthly billing',
        priceHintAnnual: 'Annual billing',
        mainFeatures: [
          ['Minutes included', ['50000']],
          ['API Access', ['Yes']],
          ['Recording', ['HD']],
          ['Recording Storage', ['900 minutes (15 hours)']],
          ['Recording Sharing', ['Yes']],
          ['Autowebinars (Promo)', ['Yes']],
          ['Conference', ['up to 100 participants']],
          ['Webinar', ['up to 1000 participants']],
        ],
        extraFeaturesTitle: 'Included in the plan:',
        extraFeatures: [],
        buyButton: 'Buy',
      },
      businessPlan: {
        title: 'Business',
        priceHintMonthly: 'Monthly billing',
        priceHintAnnual: 'Annual billing',
        mainFeatures: [
          ['Minutes included', ['100000']],
          ['API Access', ['Yes']],
          ['Recording', ['Full HD']],
          ['Recording Storage', ['1800 minutes (30 hours)']],
          ['Recording Sharing', ['Yes']],
          ['Autowebinars (Promo)', ['Yes']],
          ['Conference', ['up to 300 participants']],
          ['Webinar', ['up to 2500 participants']],
        ],
        extraFeaturesTitle: 'Included in the plan:',
        extraFeatures: ['Logo replacement'],
        buyButton: 'Buy',
      },
      enterprisePlan: {
        title: 'Enterprise',
        priceHintMonthly: '',
        priceHintAnnual: '',
        mainFeatures: [
          ['Minutes included', ['Personal calculation']],
          ['API Access', ['Yes']],
          ['Recording', ['Full HD']],
          ['Recording Storage', ['Personal calculation']],
          ['Recording Sharing', ['Yes']],
          ['Autowebinars (Promo)', ['Yes']],
          ['Conference', ['up to 1000 participants']],
          ['Webinar', ['up to 10000 participants']],
        ],
        extraFeaturesTitle: 'Included in the plan:',
        extraFeatures: ['Logo replacement'],
        buyButton: 'Submit a request',
      },
      proPlan: {
        title: 'Pro',
        titleHint: 'To purchase multiple licenses, contact Support',
        priceHintMonthly: 'Monthly billing',
        priceHintAnnual: 'Annual billing',
        mainFeatures: [
          ['Conference', ['up to 100 participants']],
          ['Webinar', ['up to 200 participants']],
          ['Duration of the meeting', ['no limits']],
          ['Recording Storage', ['5GB per license']],
          ['Recording Sharing', ['Yes']],
          ['API Access', ['No']],
        ],
        extraFeaturesTitle: 'Included in the plan:',
        buyButton: 'Buy',
      },
      businessPlanNew: {
        title: 'Business',
        priceHintMonthly: 'Monthly billing',
        priceHintAnnual: 'Annual billing',
        mainFeatures: [
          ['Conference', ['up to 300 participants']],
          ['Webinar', ['up to 500 participants']],
          ['Duration of the meeting', ['no limits']],
          ['Recording Storage', ['5GB per license']],
          ['Recording Sharing', ['Yes']],
          ['API Access', ['Yes']],
        ],
        extraFeaturesTitle: 'Included in the plan:',
        buyButton: 'Buy from 10 licenses',
      },
      personalTelebossPlan: {
        title: 'Personal',
        priceHintMonthly: 'Monthly billing',
        priceHintAnnual: 'Annual billing',
        mainFeatures: [
          ['Parallel meetings', ['No parallel meetings']],
          ['Conference', ['up to 100 participants']],
          ['Webinar', ['up to 100 participants']],
          ['API access', ['Yes']],
          ['Recording', ['HD']],
          ['Recording storage', ['1500 minutes (25 hours)']],
          ['Recording sharing', ['Yes']],
          ['Streaming through OBS', ['Yes']],
        ],
        extraFeaturesTitle: 'Included in the plan:',
        extraFeatures: [],
        buyButton: 'Buy',
      },
      enterpriseTelebossPlan: {
        title: 'Enterprise',
        priceHintMonthly: '',
        priceHintAnnual: '',
        mainFeatures: [
          ['Parallel meetings', ['Unlimited']],
          ['Conference', ['up to 1000 participants']],
          ['Webinar', ['up to 10000 participants']],
          ['API access', ['Yes']],
          ['Recording', ['Full HD']],
          ['Recording storage', ['Personal calculation']],
          ['Recording sharing', ['Yes']],
          ['Streaming through OBS', ['Yes']],
        ],
        extraFeaturesTitle: 'Included in the plan:',
        extraFeatures: ['Logo replacement'],
        buyButton: 'Submit a request',
      },
    },
    unsupportedBrowser: {
      headTitle: 'Unsupported browser',
      headTitleOutdated: 'Browser is outdated',
      title: 'Unsupported browser',
      titleOutdated: 'Browser is outdated',
      hint: 'Please change the browser to use {{platformName}}',
      hintOutdated: 'Unfortunately, the current version of your browser is not compatible with {{platformName}} service.\nFor proper functioning, please update your system or use any of the supported browsers.',
      button: 'Download on browser page',
      openApp: 'Open app',
    },
    wordsEnding: {
      day: ['day', 'days'],
      room: ['room', 'rooms'],
      participant: ['participant', 'participants'],
      second: ['second', 'seconds'],
      minute: ['minute', 'minutes'],
      byte: ['Byte', 'Bytes'],
      demos: ['shares', 'share'],
      views: ['view', 'views'],
    },
    pulse: {
      activityInClass: 'Class activity',
      interactives: 'Interactives participation',
      totalOnline: 'Total online',
      activeTab: 'Active Tab',
      interactivesHint: 'Participants who used reactions, surveys.',
      totalOnlineHint: 'Number of participants per session.',
      activeTabHint: 'Members with an open tab.',
      tooltip: 'Class activity',
      usersActivity: 'Activity',
    },
    statistics: {
      title: 'Statistics on participants',
      pulse: 'Pulse',
      listTitle: 'Room, chat and pols activities',
      cardTitle: 'Session {{date}} {{ startTime }} - {{ endTime }}',
      cardSubtitle: ' {{ participant }}',
      noSessionMessage: 'There were no sessions on this period',
      notifications: {
        errorLoadingRoomInfo: 'Error loading room info',
        errorLoadingSessionList: 'Error loading session list',
      },
    },
    safariRequestStorageAccess: {
      hint1: 'If you wish to proceed, allow access to your cookie files',
      button1: 'Allow cookies',
      hint2: 'Please accept the storage of your cookie files',
      button2: 'Accept',
      waiting: 'Waiting...',
      thanks: 'Thank you, your cookie files have been saved',
    },
    thanksPage: {
      text: 'Thank you for using {{brand}}',
      repeatCall: 'Repeat call',
      commonThanks: 'The video call is completed.\nThank you for participating!',
    },
    debug: {
      debugInfo: 'Debug info',
      peerInfo: 'Peer info',
      trackInfo: 'Track info',
      audioInfo: 'Audio tracks info',
      requestKeyFrame: 'Request key frame',
      reportIssue: 'Report a problem',
      issueDescription: 'Issue description',
      moreInfo: 'More info in developer console...',
      download: 'Download dump',
      successRequestKeyFrame: 'Request for new key frame successfully sent',
      errorRequestKeyFrame: 'Error request new key frame',
      dblClickForDebugInfo: 'Double click for show debug info',
      successIssueReport: 'Report sent successfully',
      errorIssueReport: 'Report sending error',
      updateInfo: 'Update info',
      updateAudio: 'Remount audio',
    },
    waitingRoom: {
      label: 'Waiting room',
      helpTooltip: 'The administrator can admit participants to the conference selectively, or all at once when he or she is ready to start the meeting.',
      waitingForDecision: 'Waiting for the administrator\'s decision',
      requestRejected: 'The administrator rejected the request',
      backToRoomList: 'Leave waiting room',
      allowAll: 'Let all in',
      denyAll: 'Deny for all',
    },
    announcements: {
      started: 'Started',
      stopped: 'Stopped',
      show: 'Show Banner',
      hide: 'Hide Banner',
      text: 'Banner Text',
      textButton: 'Button Text',
      link: 'Link',
      enterText: 'Enter text',
      create: 'Create new banner',
      loadError: 'Failed to load banners',
      notFoundError: 'Banner not found',
      postError: 'Failed to show banner',
      deleteError: 'Failed to hide banner',
      deleteBanner: 'Delete banner?',
      confirmAction: 'Confirm your action',
      invalidUrl: 'Link url is not valid',
      title: 'Title',
      price: 'Price',
      type: 'Banner type',
      default: 'Default',
      loadPaymentFail: 'Failed to load payment widget',
      paymentInitFail: 'Failed to open payment widget',
      paymentWrongUrl: 'Wrong payment url, should be in form of \'xxx.payform.ru\'',
      paymentError: 'Failed to process payment',
    },
    breakoutRooms: {
      dndHint: 'Group the participants by dragging them',
      clearRooms: 'Clear rooms',
      shuffleRooms: 'Shuffle',
      roomsCount: 'Rooms count',
      breakoutRoom: 'Breakout Room {{index}}',
      move: 'Move to Breakout Room {{index}}',
      unassigned: 'Unassigned',
      administrators: 'Administrators',
      startSession: 'Start session',
      stopSession: 'Stop session',
      prefix: 'Breakout Room',
      wait: 'Wait...',
      return: 'Return to main room',
      moved: 'You have been moved to {{roomName}} by administrator',
      returned: 'Session is over, you have been returned to {{roomName}}',
      deleteRoomError: 'Failed to delete breakout room, make sure it has no participants',
      startError: 'Failed to start session',
      stopError: 'Failed to stop session',
      loadRoomsError: 'Failed to load breakout rooms',
      sessionOver: 'Session is closed by administrator',
      sessionStarted: 'Session is started by administrator',
      enter: 'Join breakout room',
    },
    userList: {
      turnOffAllCameras: 'Turn off all cameras',
      turnOffAllMicrophones: 'Turn off all microphones',
      working: 'enabled {{ withActiveDevices }}/{{ all }}',
    },
    events: {
      eventDate: '{{date}} at {{time}}',
      eventDateLaunch: 'Launch: {{date}} at {{time}}',
      participantsPast: 'Total participants',
      participantsNow: 'Participating',
      participantsFeature: 'Not started',
      continuous: 'Continuous',
      runningNow: 'Running now',
      notStarted: 'Not started',
      completed: 'Completed',
      edit: 'Edit',
      sortByStartTime: 'Start time',
      uploadVideo: 'Upload video',
      chooseVideo: 'Choose video',
      noVideo: 'Video is not added yet\nPlease choose video for autowebinar',
      chatInteractions: 'Chat and interactions',
      noChatInteractions: 'Chat and interactions is not added yet\nAdd them by uploading .json file',
      uploadJson: 'Upload .json',
      replaceJson: 'Replace .json',
      uploadAndReplaceVideo: 'Upload and replace video',
      chooseAndReplaceVideo: 'Choose and replace video',
      announcements: 'Announcements',
      showTime: 'Display time',
      ongoingBadge: 'Ongoing',
      completedBadge: 'Completed',
      recurrentBadge: 'Recurrent',
      recurrentOnceBadge: 'Once',
      notStartedBadge: 'Planned',
      failedToFetchSessions: 'Failed to load users sessions',
      failedToFetchEvents: 'Failed to load autowebinar schedule',
      failedToDownloadReport: 'Failed to download analytics report',
      startTime: 'Start time',
    },
    language: {
      ru: 'Русский',
      en: 'English',
    },
    theme: {
      theme: 'Color theme',
      light: 'Light theme',
      dark: 'Dark theme',
      auto: 'System theme',
    },
    afterRegisterPopup: {
      title1: 'Congratulations!',
      title2: 'Registration successfully completed',
      checkList: [
        'Account creation',
        'Email confirmation',
        'First space created «{{ spaceName }}»',
        'First meeting created «{{ roomName }}»',
      ],
      alert1: 'To get started with the service, host your first meeting.',
      alert2: 'You can start or finish the meeting right after it begins.',
      buttonText: 'Join the meeting «{{ roomName }}»',
    },
    calls: {
      callLoadError: 'Failed to load call information',
      activeCallLoadError: 'Failed to load active call',
    },
    deviceSettings: {
      noMic: 'Not detected',
      noCam: 'Not detected',
      retryHint: 'The camera or microphone is already in use in another application',
      backBlur: 'Background blur',
      noiseSuppression: 'Noise suppresion',
      frontalCamera: 'Frontal camera',
      backCamera: 'Back camera',
      offCamera: 'Camera off',
      micOn: 'Microphone on',
      micOff: 'Microphone off',
      micNotAvailable: 'Microphone: Not available',
      camNotAvailable: 'Camera: Not available',
      deviceSelectError: 'Failed to select device',
      retryDetect: 'Retry detect devices',
      switchCamera: 'Switch camera',
      testSound: 'Test sound',
    },
    electron: {
      thickness: 'Thickness',
      opacity: 'Opacity',
      board: 'Board',
      drawToolbar: 'Draw panel',
      closeApp: 'Are you sure you want to close the application?',
    },
    unknownSize: 'Unknown size',
    sizeInBytes: ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB'],
    referralCode: {
      enterCode: 'Enter promo code',
      codePlaceholder: 'Promo code',
      apply: 'Apply',
      validationError: 'Invalid code format',
    },
  },
};
